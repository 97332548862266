import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest } from 'redux-saga/effects';
import { authStateChanged } from './authSlice';
import { UserAuthState } from './types';

('./authSlice');

export function* authStateChangedRequested(
	action: PayloadAction<UserAuthState>
) {
	const { userType } = action.payload;

	// yield put(verifyUserAuthStateSucceeded({ isUserAuthenticated }));
}

export default function* AuthSaga() {
	// yield takeLatest(authStateChanged.type, authStateChangedRequested);
}
