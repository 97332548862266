import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authSlice from '../features/auth/authSlice';
import AuthSaga from '../features/auth/saga';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
	reducer: {
		authSlice,
		// posts: postsReducer,
		// comments: commentsReducer,
		// users: usersReducer,
	},
	middleware: [...getDefaultMiddleware(), sagaMiddleware],
});

sagaMiddleware.run(AuthSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
