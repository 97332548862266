import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
	useIonRouter,
} from '@ionic/react';

import { TextFieldTypes } from '@ionic/core';
import CustomForm from '../../../components/CustomForm/CustomForm';
import { REGEX, VALIDATION_MESSAGE } from '../../../app/utils/constants';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { registerAgentAccount } from '../../../services/firestore';

const SuperAdminCreateAgentAccount: React.FC = () => {
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const router = useIonRouter();

	const fields: any = [
		{
			label: 'Nome e cognome',
			props: {
				name: 'name',
				type: 'text' as TextFieldTypes,
				placeholder: 'Nome e cognome',
			},
		},
		{
			label: 'Indirizzo',
			props: {
				name: 'address',
				type: 'text' as TextFieldTypes,
				placeholder: 'Indirizzo',
			},
		},
		{
			label: 'Città',
			props: {
				name: 'city',
				type: 'text' as TextFieldTypes,
				placeholder: 'Città',
			},
		},
		{
			label: 'Numero di telefono',
			props: {
				name: 'telephone',
				type: 'tel' as TextFieldTypes,
				placeholder: 'Numero di telefono',
			},
		},
		{
			label: 'Email',
			props: {
				name: 'email',
				type: 'email' as TextFieldTypes,
				placeholder: 'Email',
			},
		},
	];

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.matches(REGEX.EMAIL, VALIDATION_MESSAGE.EMAIL_NOT_VALID)
			.required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onTouched',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: any) => {
		presentLoader('Registrazione in corso...');

		try {
			const result: any = await registerAgentAccount(data);

			if (result.data?.error) {
				if (result.data.error === 'auth/email-already-exists') {
					presentAlert('Email già registrata su JackShop');
				} else {
					presentAlert(`Errore imprevisto durante la creazione dell'account`);
				}
			} else {
				presentAlert('Registrazione avvenuta correttamente', [
					{
						text: 'OK',
						handler: () => {
							router.push('/super-admin-home');
						},
					},
				]);
			}
		} catch (e) {
			console.error(e);
			presentAlert("Errore imprevisto durante la creazione dell'account");
		} finally {
			dismissLoader();
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='primary'>
					<IonTitle>Crea account agente</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<CustomForm
								fields={fields}
								validationSchema={validationSchema}
								submitLabel={'Crea account'}
								onSubmit={onSubmit}
							></CustomForm>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default SuperAdminCreateAgentAccount;
