import {
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

const SuperAdminHome: React.FC = () => {
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='primary'>
					<IonTitle>Admin Home</IonTitle>
					<IonButtons slot='start'>
						<IonMenuButton
							autoHide={false}
							menu='superAdminMenu'
						></IonMenuButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<IonButton
								color='primary'
								className='standard-btn'
								routerLink='/create-agent-account'
							>
								Crea account agente
							</IonButton>

							<IonButton
								color='primary'
								className='standard-btn'
								routerLink='/super-admin-registered-businesses-list'
							>
								Lista attività registrate
							</IonButton>
							<IonButton
								color='primary'
								className='standard-btn'
								routerLink='/super-admin-registered-agents-list'
							>
								Lista agenti registrati
							</IonButton>
							{/* <IonButton
								color='primary'
								className='standard-btn'
								routerLink='/super-admin-registered-influencers-list'
							>
								Lista influencers registrati
							</IonButton> */}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default SuperAdminHome;
