import {
	IonBackButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonRow,
	IonSearchbar,
	IonTitle,
	IonToolbar,
	useIonLoading,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { getRegisteredBusinesses } from '../../services/firestore';
import './RegisteredBusinesses.scss';

const RegisteredBusinesses: React.FC = () => {
	const [businesses, setBusinesses] = useState<any>([]);
	const [presentLoader, dismissLoader] = useIonLoading();
	const [searchCity, setSearchCity] = useState<string>('');

	useEffect(() => {
		const getBusinesses = async () => {
			presentLoader();
			const registeredBusinesses = await getRegisteredBusinesses(
				searchCity,
				false
			);
			if (!(registeredBusinesses.data as any).error) {
				setBusinesses(registeredBusinesses.data);
			}

			dismissLoader();
		};

		getBusinesses();
	}, [searchCity]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Attività Convenzionate</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton
							defaultHref='/customer-home'
							disabled={false}
						></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='registered-businesses-content' fullscreen>
				<IonGrid>
					<IonRow className='ion-justify-content-center top-section'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<IonSearchbar
								value={searchCity}
								onIonChange={(e) => setSearchCity(e.detail.value!)}
								placeholder='Ricerca per città...'
								inputMode='text'
								debounce={1000}
							></IonSearchbar>
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center top-section no-data'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							{businesses?.length === 0 && (
								<p className='ion-text-center ion-margin-top'>
									Non è presente nessuna attività
								</p>
							)}
						</IonCol>
					</IonRow>
					<IonRow className='ion-justify-content-center top-section'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							<IonList className='businesses-list-wrapper'>
								{businesses?.map((business: any) => {
									return (
										<div className='item-wrapper' key={business.businessName}>
											<p className='header-text'>{business.businessName}</p>
											<IonItem
												key={business.businessName}
												lines='full'
												className='list-item'
											>
												<IonLabel className='description'>
													<p className='text city'>
														{business.city.charAt(0).toUpperCase() +
															business.city.slice(1)}
													</p>
													<p className='text'>{business.address}</p>
												</IonLabel>
											</IonItem>
											<hr></hr>
										</div>
									);
								})}
							</IonList>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default RegisteredBusinesses;
