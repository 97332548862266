import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { bagCheckOutline, refreshOutline, star } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { getBusinessTransactionsRealtime } from '../../../services/firestore';

import './BusinessTransactions.scss';
const BusinessTransactions: React.FC = () => {
	const [transactions, setTransactions] = useState<any[]>([]);
	const [lastTransaction, setLastTransaction] = useState();
	useEffect(() => {
		getBusinessTransactionsRealtime((snapshot) => {
			snapshot.docChanges().forEach((change: any) => {
				setTransactions((prevState) => {
					const pState = [...prevState];

					if (pState.length > 0) {
						const doc = change.doc.data();
						const currentDocTime = doc.creationDate.toDate().getTime();
						const firstDocTime = pState[0].creationDate.toDate().getTime();

						if (currentDocTime > firstDocTime) {
							pState.unshift(change.doc.data());
						} else {
							pState.push(change.doc.data());
						}
					} else {
						pState.push(change.doc.data());
					}

					return pState;
				});
			});
		});
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Transazioni recenti</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonList className='transaction-list-wrapper'>
					{transactions?.map((t) => {
						return (
							<div className='item-wrapper' key={t.creationDate.seconds}>
								<IonItem
									key={t.creationDate.seconds}
									lines='full'
									className='list-item'
								>
									<IonIcon
										slot='start'
										icon={bagCheckOutline}
										className='icon'
									></IonIcon>
									<IonLabel className='description'>
										<p className='text'>
											<span className='amount'>
												{t.isWithdrawalFromCustomer
													? 'Credito Jackshop cliente'
													: 'Importo'}{' '}
											</span>
											{t.amountSpent} €
										</p>
										<small className='text'>
											{new Intl.DateTimeFormat('it', {
												dateStyle: 'long',
												timeStyle: 'short',
											}).format(t.creationDate.toDate())}
										</small>
									</IonLabel>
								</IonItem>
							</div>
						);
					})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default BusinessTransactions;
