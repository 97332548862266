import {
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { logOut } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { authStateChanged } from './features/auth/authSlice';
import { logout } from './services/firestore';
import './InfluencerMenu.scss';

const InfluencerMenu: React.FC = () => {
	const dispatch = useDispatch();

	return (
		<IonMenu
			side='start'
			contentId='influencerMenu'
			menuId='influencerMenu'
			id='influencerMenu'
			className='side-menu'
			type='overlay'
		>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Menu</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList>
					{/* <IonMenuToggle>
						<IonItem>
							<IonLabel>Home</IonLabel>
							<IonIcon slot='start' icon={home}></IonIcon>
						</IonItem>
					</IonMenuToggle>
					<IonMenuToggle>
						<IonItem>
							<IonLabel>Transazioni</IonLabel>
							<IonIcon slot='start' icon={listCircle}></IonIcon>
						</IonItem>
					</IonMenuToggle> */}
					<IonMenuToggle>
						<IonItem
							onClick={async () => {
								await logout();
								dispatch(
									authStateChanged({
										userType: undefined,
										userUID: undefined,
										name: undefined,
									})
								);
							}}
						>
							<IonLabel>Logout</IonLabel>
							<IonIcon slot='start' icon={logOut}></IonIcon>
						</IonItem>
					</IonMenuToggle>
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default InfluencerMenu;
