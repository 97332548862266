import {
	IonBackButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonRow,
	IonSearchbar,
	IonTitle,
	IonToolbar,
	useIonLoading,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { getRegisteredBusinesses } from '../../services/firestore';
import './RegisteredBusinessesOnline.scss';

const RegisteredBusinesses: React.FC = () => {
	const [businesses, setBusinesses] = useState<any>([]);
	const [presentLoader, dismissLoader] = useIonLoading();

	useEffect(() => {
		const getBusinesses = async () => {
			presentLoader();
			const registeredBusinesses = await getRegisteredBusinesses('', true);
			setBusinesses(registeredBusinesses.data);
			dismissLoader();
		};

		getBusinesses();
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Attività Convenzionate Online</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton
							defaultHref='/customer-home'
							disabled={false}
						></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='registered-businesses-content' fullscreen>
				<IonGrid>
					<IonRow className='ion-justify-content-center top-section no-data'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							{businesses?.length === 0 && (
								<p className='ion-text-center ion-margin-top'>
									Non è presente nessuna attività online
								</p>
							)}
						</IonCol>
					</IonRow>
					{businesses?.length !== 0 && (
						<>
							<IonRow className='ion-justify-content-center top-section'>
								<IonCol
									size-xs='12'
									size-sm='12'
									size-md='8'
									size-lg='6'
									size-xl='6'
								>
									<small className='small-desc'>
										Puoi partecipare all&apos;estrazione del jackpot anche
										attraverso gli acquisti presso i tuoi rivenditori preferiti
										online
									</small>
								</IonCol>
							</IonRow>
							<IonRow className='ion-justify-content-center top-section'>
								<IonCol
									size-xs='12'
									size-sm='12'
									size-md='8'
									size-lg='6'
									size-xl='6'
								>
									<IonList className='businesses-list-wrapper'>
										{businesses?.map((business: any) => {
											return (
												<div
													className='item-wrapper'
													key={business.businessName}
												>
													<p className='header-text'>{business.businessName}</p>
													<IonItem
														key={business.businessName}
														lines='full'
														className='list-item'
													>
														<IonLabel className='link-wrapper'>
															<a
																href={business.url}
																className='link'
																target='_blank'
																rel='noreferrer'
															>
																Visita {business.businessName}
															</a>
														</IonLabel>
													</IonItem>
													<hr></hr>
												</div>
											);
										})}
									</IonList>
								</IonCol>
							</IonRow>
						</>
					)}
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default RegisteredBusinesses;
