import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonRouter,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import './ChooseTransactionType.scss';
const ChooseTransactionType: React.FC = () => {
	const history = useHistory<any>();
	const router = useIonRouter();
	const [customerUID, setCustomerUID] = useState('');

	useEffect(() => {
		if (history.location?.state?.qr) {
			setCustomerUID(history.location.state.qr);
			console.log('customer qr: ' + history.location.state.qr);
		} else {
			router.push('/business-scan-customer-qr', 'root', 'push', {});
		}
	}, []);

	const onButtonClick = (isCustomerWithdrawal: boolean) => {
		console.log('qr e uid ' + customerUID + ' | ' + isCustomerWithdrawal);
		history.push({
			pathname: '/business-transaction-import',
			state: {
				qr: customerUID,
				isCustomerWithdrawal,
			},
		});
		// router.push('/business-transaction-import', 'forward', 'push', {});
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Scelta Transazione</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonButton
					color='primary'
					className='standard-btn'
					onClick={() => onButtonClick(false)}
				>
					Crea nuova transazione
				</IonButton>
				<IonButton
					color='primary'
					className='standard-btn'
					onClick={() => onButtonClick(true)}
				>
					Preleva credito utente
				</IonButton>
			</IonContent>
		</IonPage>
	);
};

export default ChooseTransactionType;
