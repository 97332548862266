import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { UserAuthState } from './types';

// Define the initial state using that type
const initialState: UserAuthState = {
	name: undefined,
	userUID: undefined,
	userType: undefined,
};

export const authSlice = createSlice({
	name: 'auth',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		authStateChanged: (state, action: PayloadAction<UserAuthState>) => {
			const { name, userUID, userType } = action.payload;

			return {
				...state,
				name,
				userType,
				userUID,
			};
		},
	},
});

export const { authStateChanged } = authSlice.actions;

export default authSlice.reducer;
