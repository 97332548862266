import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
	useIonRouter,
} from '@ionic/react';

import { TextFieldTypes } from '@ionic/core';
import CustomForm from '../../../components/CustomForm/CustomForm';
import { REGEX, VALIDATION_MESSAGE } from '../../../app/utils/constants';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import {
	registerAgentAccount,
	updateBusinessCredit,
} from '../../../services/firestore';
import { useLocation } from 'react-router';
import { useMemo } from 'react';

const SuperAdminUpdateBusinessCredit: React.FC = () => {
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const router = useIonRouter();
	function useQuery() {
		const { search } = useLocation();

		return useMemo(() => new URLSearchParams(search), [search]);
	}

	const query = useQuery();

	const businessName = query.get('businessName');
	const currentCredit = query.get('currentCredit');
	const businessUid = query.get('businessUid');

	const fields: any = [
		{
			label: 'Nome Attività',
			props: {
				name: 'businessName',
				type: 'text' as TextFieldTypes,
				disabled: true,
				value: businessName,
			},
		},
		{
			label: 'Credito attuale',
			props: {
				name: 'businessCurrentCredit',
				type: 'number' as TextFieldTypes,
				disabled: true,
				value: currentCredit,
			},
		},
		{
			label: 'Nuovo credito attività',
			props: {
				name: 'businessCredit',
				type: 'number' as TextFieldTypes,
				placeholder: 'Nuovo credito attività',
			},
		},
	];

	const validationSchema = Yup.object().shape({
		// email: Yup.number().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	});

	// const {
	// 	formState: { errors },
	// } = useForm({
	// 	mode: 'onTouched',
	// 	reValidateMode: 'onChange',
	// });

	const onSubmit = async (data: any) => {
		presentLoader('Aggiornamento credito in corso...');

		const { businessCredit } = data;
		try {
			const result: any = await updateBusinessCredit(
				businessUid!,
				businessCredit
			);

			if (result.data?.error) {
				if (result.data.error === '') {
					presentAlert("L'attività non esiste");
				} else {
					presentAlert(`Errore imprevisto durante l'aggiornamento del credito`);
				}
			} else {
				presentAlert(
					`Credito dell'attività ${businessName} aggiornato correttamente`,
					[
						{
							text: 'OK',
							handler: () => {
								router.push('/super-admin-registered-businesses-list');
							},
						},
					]
				);
			}
		} catch (e) {
			console.error(e);
			presentAlert("Errore imprevisto durante l'aggiornamento del credito");
		} finally {
			dismissLoader();
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='primary'>
					<IonTitle>Aggiorna credito attività</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<CustomForm
					fields={fields}
					validationSchema={validationSchema}
					submitLabel={'Aggiorna credito'}
					onSubmit={onSubmit}
				></CustomForm>
			</IonContent>
		</IonPage>
	);
};

export default SuperAdminUpdateBusinessCredit;
