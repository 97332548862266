import { TextFieldTypes } from '@ionic/core';
import {
	IonButton,
	IonContent,
	IonPage,
	useIonLoading,
	useIonRouter,
	useIonToast,
} from '@ionic/react';
import { Dispatch, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
	ERROR_CODE,
	ERROR_MESSAGE,
	PLACEHOLDER,
	REGEX,
	VALIDATION_MESSAGE,
} from '../../app/utils/constants';
import darkBorder from '../../assets/dark-border.png';
import logo from '../../assets/logo-transp.png';
import redBorder from '../../assets/red-border.png';
import CustomForm from '../../components/CustomForm/CustomForm';
import { authStateChanged } from '../../features/auth/authSlice';
import { UserType } from '../../features/auth/types';
import { loginWithEmail } from '../../services/firestore';
import { getUserData, setUserData } from '../../services/storage';
import { UserData } from '../../types';
import './Login.scss';

const Login: React.FC = () => {
	const dispatch: Dispatch<any> = useAppDispatch();
	const router = useIonRouter();
	const [presentLoader, dismissLoader] = useIonLoading();
	const [presentToast, dismissToast] = useIonToast();

	const userType = useAppSelector((state) => state.authSlice.userType);

	useEffect(() => {
		presentLoader('Attendere...', 1000);
	}, []);

	useEffect(() => {
		dismissLoader();

		switch (userType) {
			case UserType.CUSTOMER:
				router.push('/customer-home', 'forward');
				break;
			case UserType.BUSINESS:
				router.push('/business-home');
				break;
			case UserType.INFLUENCER:
				router.push('/influencer-home');
				break;
			case UserType.AGENT:
				router.push('/agent-home', 'forward');
				break;
			case UserType.SUPERADMIN:
				router.push('/super-admin-home', 'forward');
				break;
			default:
				router.push('/');
		}
	}, [userType]);

	const fields = [
		{
			label: 'Email',
			props: {
				name: 'email',
				type: 'email' as TextFieldTypes,
				placeholder: PLACEHOLDER.INSERT_EMAIL,
				autocomplete: 'new-password',
			},
		},
		{
			label: 'Password',
			props: {
				name: 'password',
				type: 'password' as TextFieldTypes,
				placeholder: PLACEHOLDER.INSERT_PASSWORD,
				autocomplete: 'new-password',
			},
		},
	];

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.matches(REGEX.EMAIL, VALIDATION_MESSAGE.EMAIL_NOT_VALID)
			.required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		password: Yup.string().min(6, VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onTouched',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: any) => {
		presentLoader('Accesso in corso...');

		let userCredentials;
		try {
			userCredentials = await loginWithEmail(data.email, data.password);
		} catch (error: any) {
			if (error.code === ERROR_CODE.AUTH_WRONG_PASSWORD) {
				presentToast(ERROR_MESSAGE.WRONG_PASSWORD, 2000);
			} else if (error.code === ERROR_CODE.AUTH_USER_NOT_FOUND) {
				presentToast(ERROR_MESSAGE.EMAIL_NOT_REGISTERED, 2000);
			} else {
				presentToast(ERROR_MESSAGE.UNEXPECTED_ERROR, 2000);
			}
		} finally {
			dismissLoader();
		}

		try {
			const token = await userCredentials?.user.getIdTokenResult(true);
			if (token) {
				const userData: UserData = {
					name: token?.claims.name as string,
					userUID: token?.claims.user_id as string,
				};

				// console.log('gonna wait user data');
				// const v = await setUserData(userData);
				// console.log('after set data ' + JSON.stringify(userData, null, 2));
				// if (token) {
				// 	if (token.claims) {
				// 		// Business account
				// 		if (token.claims.businessAccount) {
				// 			userData.isAdmin = true;
				// 			dispatch(
				// 				authStateChanged({
				// 					userType: UserType.BUSINESS,
				// 					name: userData.name,
				// 					userUID: userData.userUID,
				// 				})
				// 			);
				// 		}

				// 		// Influencer account
				// 		if (token.claims.influencerAccount) {
				// 			userData.isInfluencer = true;
				// 			dispatch(
				// 				authStateChanged({
				// 					userType: UserType.INFLUENCER,
				// 					name: userData.name,
				// 					userUID: userData.userUID,
				// 				})
				// 			);
				// 		}

				// 		// Agent account
				// 		if (token.claims.agentAccount) {
				// 			userData.isAgent = true;
				// 			dispatch(
				// 				authStateChanged({
				// 					userType: UserType.AGENT,
				// 					name: userData.name,
				// 					userUID: userData.userUID,
				// 				})
				// 			);
				// 		}

				// 		// Super admin account
				// 		if (token.claims.masterAccount) {
				// 			userData.isSuperAdmin = true;
				// 			dispatch(
				// 				authStateChanged({
				// 					userType: UserType.SUPERADMIN,
				// 					name: userData.name,
				// 					userUID: userData.userUID,
				// 				})
				// 			);
				// 		}
				// 	} else {
				// 		dispatch(
				// 			authStateChanged({
				// 				userType: UserType.CUSTOMER,
				// 				name: userData.name,
				// 				userUID: userData.userUID,
				// 			})
				// 		);
				// 	}
				// }
			}
		} catch (error: any) {
			presentToast(ERROR_MESSAGE.UNEXPECTED_ERROR, 2000);
		}
	};

	return (
		<IonPage>
			<IonContent>
				<div className='heading-logo-image-wrapper'>
					<img className='logo' src={logo} />
					<img className='dark-border' src={darkBorder} />
					<img className='red-border' src={redBorder} />
				</div>
				<div className='ion-padding'>
					<CustomForm
						fields={fields}
						validationSchema={validationSchema}
						submitLabel={'ACCEDI'}
						onSubmit={onSubmit}
					></CustomForm>

					<IonButton
						className='standard-btn'
						routerLink='/customer-registration'
						routerDirection='forward'
						color='primary'
						fill='outline'
					>
						REGISTRATI
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Login;
