import {
	IonBackButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonRouter,
} from '@ionic/react';
import { Dispatch, useState } from 'react';
import QrReader from 'react-qr-reader';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import darkBorder from '../../../assets/dark-border.png';
import logo from '../../../assets/logo-transp.png';
import redBorder from '../../../assets/red-border.png';
import './BusinessScanCustomerQR.scss';

const BusinessScanCustomerQR: React.FC = () => {
	const [qr, setQr] = useState('');
	const dispatch: Dispatch<any> = useDispatch();
	const router = useIonRouter();
	const history = useHistory();

	const handleScan = (data: any) => {
		if (data) {
			setQr(data);
			history.push({
				pathname: '/business-choose-transaction-type',
				state: {
					qr: data,
				},
			});
			// router.push('/business-choose-transaction-type', 'forward', 'push', {});
		}
		// return null;
	};
	const handleError = (err: any) => {
		console.error(err);
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Scan QR</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className='heading-logo-image-wrapper'>
					<img className='logo' src={logo} />
					<img className='dark-border' src={darkBorder} />
					<img className='red-border' src={redBorder} />
				</div>

				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='2'
							size-xl='2'
						>
							<div className='content-wrapper ion-padding'>
								<div>
									<QrReader
										delay={300}
										onError={handleError}
										onScan={handleScan}
										style={{ width: '100%' }}
									/>
								</div>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default BusinessScanCustomerQR;
