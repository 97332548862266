import { TextFieldTypes } from '@ionic/core';
import {
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
	useIonRouter,
} from '@ionic/react';
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import CustomForm from '../../../components/CustomForm/CustomForm';
import {
	consumeCustomerCredit,
	createTransaction,
} from '../../../services/firestore';
import './BusinessTransactionImport.scss';

const BusinessTransactionImport: React.FC = () => {
	const dispatch: Dispatch<any> = useDispatch();
	const router = useIonRouter();
	const history = useHistory<any>();
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const [customerUID, setCustomerUID] = useState('');
	const [customerWithdrawal, setCustomerWithdrawal] = useState<boolean>(false);
	const [amount, setAmount] = useState(0);
	const [headerTitle, setHeaderTitle] = useState<string>();

	useEffect(() => {
		console.log('state ', history.location?.state);
		if (history.location?.state?.qr) {
			const { qr, isCustomerWithdrawal } = history.location.state;
			setCustomerUID(qr);
			setCustomerWithdrawal(isCustomerWithdrawal);
			setHeaderTitle(
				isCustomerWithdrawal ? 'Preleva credito utente' : 'Crea Transazione'
			);
		} else {
			router.push('/business-scan-customer-qr', 'root', 'push', {});
		}
	}, []);

	const validationSchema = Yup.object().shape({
		amount: Yup.number()
			.typeError("L'importo deve essere un numero")
			.required("Inserire l'ammontare della transazione"),
	});

	const fields = [
		{
			label: 'Importo',
			required: true,
			requiredOptions: {
				minLength: 1,
			},
			props: {
				name: 'amount',
				type: 'number' as TextFieldTypes,
				// inputmode: 'numeric' as typeof NUMERIC,
				placeholder: "Inserisci l'importo",
			},
		},
	];

	const showConfirmationAlert = async (data: any) => {
		const alertMessage = customerWithdrawal
			? `Confermi il prelievo del credito cliente di importo ${data.amount}€?`
			: `Confermi la transazione di importo ${data.amount}€?`;
		presentAlert(alertMessage, [
			{
				text: 'OK',
				handler: async () => {
					startOperation(data);
				},
			},
			{
				text: 'Annulla',
			},
		]);
	};

	const startOperation = async (data: any) => {
		presentLoader('Operazione in corso...');

		let response: any;
		if (customerWithdrawal) {
			response = await consumeCustomerCredit(data.amount, customerUID);
		} else {
			response = await createTransaction(data.amount, customerUID);
		}

		dismissLoader();

		if (!response.data?.error) {
			presentAlert('Operazione terminata correttamente.');
			router.push('/business-home', 'root', 'push', {});
		} else {
			if (response.data?.error === 'credit_1/error') {
				presentAlert(
					'Credito attività non sufficiente. Contattare il supporto per una ricarica.'
				);
			} else if (response.data?.error === 'credit_2/error') {
				presentAlert(
					'Credito attività non configurato. Contattare il supporto.'
				);
			} else if (response.data?.error === 'CUSTOMER_CREDIT_NOT_ENOUGH') {
				presentAlert('Credito utente non sufficiente.');
			} else {
				presentAlert(
					'Errore imprevisto durante lo scan del codice utente.\nRiprovare più tardi.'
				);
			}
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>{headerTitle}</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				<CustomForm
					fields={fields}
					validationSchema={validationSchema}
					submitLabel={'PROCEDI'}
					onSubmit={showConfirmationAlert}
				></CustomForm>
			</IonContent>
		</IonPage>
	);
};

export default BusinessTransactionImport;
