import { yupResolver } from '@hookform/resolvers/yup';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import { useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import './CustomForm.scss';

type Props = {
	fields: any;
	validationSchema: any;
	submitLabel: string;
	onSubmit: (arg0: any) => void;
	clearForm?: boolean;
};

const CustomForm: React.FC<Props> = ({
	fields,
	validationSchema,
	submitLabel,
	onSubmit,
	clearForm,
}) => {
	useEffect(() => {
		if (clearForm) {
			reset();
		}
	}, [clearForm]);

	const { register, handleSubmit, formState, reset } = useForm({
		mode: 'onTouched',
		reValidateMode: 'onChange',
		resolver: yupResolver(validationSchema),
	});

	const { errors } = formState;

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{fields.map((field: any, index: any) => {
				const { label, required, requiredOptions, props } = field;

				return (
					<div key={index}>
						<IonItem
							key={`form_field_${index}`}
							className='standard-input'
							lines='full'
						>
							<IonLabel position='stacked'>{label}</IonLabel>
							<IonInput
								autocomplete={'off'}
								{...props}
								{...register(props.name, { required, ...requiredOptions })}
							/>
						</IonItem>

						{errors[props.name]?.message && (
							<IonItem className='error-wrapper'>
								<IonIcon
									className='icon'
									slot='start'
									icon={alertCircleOutline}
									color='danger'
								/>
								<span className='error-message'>
									{errors[props.name]?.message}
								</span>
							</IonItem>
						)}
					</div>
				);
			})}

			<IonButton className='standard-btn' color='primary' type={'submit'}>
				{submitLabel}
			</IonButton>
		</form>
	);
};

export default CustomForm;
