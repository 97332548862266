import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
} from '@ionic/react';
import { checkmarkOutline, home, mailUnreadOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import {
	getAllRegisteredInfluencersRealtime,
	sendConfirmationEmail,
} from '../../../services/firestore';

const RegisteredInfluencersList: React.FC = () => {
	const [influencers, setInfluencers] = useState<any[]>([]);
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();

	useEffect(() => {
		getAllRegisteredInfluencersRealtime((snapshot) => {
			snapshot.docChanges().forEach((change: any) => {
				setInfluencers((prevState) => {
					const pState = [...prevState];

					const currentDocument = change.doc.data();
					currentDocument.id = change.doc.id;

					if (change.type === 'added') {
						if (pState.length > 0) {
							const doc = change.doc.data();
							const currentDocTime = doc.creationDate.toDate().getTime();
							const firstDocTime = pState[0].creationDate.toDate().getTime();

							if (currentDocTime > firstDocTime) {
								pState.unshift(change.doc.data());
							} else {
								pState.push(change.doc.data());
							}
						} else {
							pState.push(change.doc.data());
						}

						return pState;
					}

					if (change.type === 'modified') {
						const influencers = pState.map((influencer) => {
							if (influencer.email === change.doc.data().email) {
								const doc = change.doc.data();
								doc.id = change.doc.id;
								return doc;
							}
							return influencer;
						});

						return influencers;
					}
					return prevState;
				});
			});
		});
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='primary'>
					<IonTitle>Influencers Registrati</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonList className='influencers-list-wrapper'>
					{influencers?.length === 0 && (
						<div className='no-data ion-padding ion-margin ion-text-center'>
							Non sono presenti influencers registrati
						</div>
					)}
					{influencers?.map((i) => {
						return (
							<div className='item-wrapper' key={i.creationDate.seconds}>
								<p className='header-text'>{i.name}</p>
								<IonItem
									key={i.creationDate.seconds}
									lines='full'
									className='list-item'
								>
									<IonLabel className='description'>
										<p className='text'>
											<span className='amount'>Email </span>
											{i.email}
										</p>
										<p className='text'>
											<span className='amount'>Città </span>
											{i.city}
										</p>
										<p className='text'>
											<span className='amount'>Indirizzo </span>
											{i.address}
										</p>
										<p className='text'>
											<span className='amount'>Tel. </span>
											{i.telephone}
										</p>
										<p className='text'>
											<span className='amount'>Codice Influencer </span>
											{i.influencerCode}
										</p>
										<small className='text'>
											Il{' '}
											{new Intl.DateTimeFormat('it', {
												dateStyle: 'long',
												timeStyle: 'short',
											}).format(i.creationDate.toDate())}
										</small>
									</IonLabel>
								</IonItem>
								{i.state !== 'ENABLED' && (
									<IonButton
										color={i.isEmailSent ? 'success' : 'primary'}
										className='standard-btn'
										disabled={i.isEmailSent}
										onClick={async () => {
											presentLoader('Invio email...');
											try {
												await sendConfirmationEmail(i.email, 'i');
												dismissLoader();
												presentAlert('Mail di conferma inviata correttamente');
											} catch (e) {
												dismissLoader();
												presentAlert(
													"Errore durante l'invio della mail di conferma"
												);
											} finally {
											}
										}}
									>
										{!i.isEmailSent
											? 'Invia email di attivazione'
											: 'In attesa di conferma'}
										<IonIcon slot='start' icon={mailUnreadOutline}></IonIcon>
									</IonButton>
								)}
								{i.state === 'ENABLED' && (
									<IonItem color='success' className='ion-margin-top'>
										<IonLabel>Influencer abilitato</IonLabel>
										<IonIcon icon={checkmarkOutline} slot='start' />
									</IonItem>
								)}
								<hr />
							</div>
						);
					})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default RegisteredInfluencersList;
