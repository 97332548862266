import { SocialSharing } from '@ionic-native/social-sharing';
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonRouter,
} from '@ionic/react';
import {
	checkmarkCircle,
	logoWhatsapp,
	personCircleOutline,
	shuffle,
} from 'ionicons/icons';
import { Dispatch, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { UserType } from '../../features/auth/types';
import {
	getCustomerCreditRealtime,
	getJackpotRealtime,
} from '../../services/firestore';
import { getUserData } from '../../services/storage';
import './CustomerWinList.scss';
import darkBorder from '../../assets/dark-border.png';
import logo from '../../assets/logo-transp.png';
import redBorder from '../../assets/red-border.png';

const CustomerWinList: React.FC = () => {
	const [wins, setWins] = useState<any[]>([]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Le mie vincite</IonTitle>
					<IonButtons slot='start'>
						<IonMenuButton autoHide={false} menu='customerMenu'></IonMenuButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='customer-win-list' fullscreen>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='8'
							size-lg='6'
							size-xl='6'
						>
							{wins?.length === 0 && (
								<p className='no-transactions ion-text-center ion-margin-top'>
									Non è presente ancora nessuna vincita
								</p>
							)}
							<IonList className='transaction-list-wrapper'>
								{wins?.map((t) => {
									return (
										<div className='item-wrapper' key={t.creationDate.seconds}>
											<p className='header-text'>{t.businessName}</p>
											<IonItem
												// key={t.creationDate.seconds}
												lines='full'
												className='list-item'
											>
												{/* <IonIcon
													slot='start'
													icon={
														t.isWithdrawalFromCustomer
															? cashOutline
															: bagCheckOutline
													}
													className='icon'
												></IonIcon>
												<IonLabel className='description'>
													<p className='text'>
														<span className='amount'>
															{t.isWithdrawalFromCustomer
																? 'Utilizzato credito Jackshop'
																: 'Spesa'}{' '}
														</span>
														{t.amountSpent} €
													</p>
													<small className='text'>
														{new Intl.DateTimeFormat('it', {
															dateStyle: 'long',
															timeStyle: 'short',
														}).format(t.creationDate.toDate())}
													</small>
												</IonLabel> */}
											</IonItem>
										</div>
									);
								})}
							</IonList>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default CustomerWinList;
