import { Storage } from '@capacitor/storage';
import { UserData } from '../types';

export const setUserData = async (userData: UserData) => {
	await Storage.set({ key: 'userData', value: JSON.stringify(userData) });
	console.log('data set on storage ' + JSON.stringify(userData));
};

export const getUserData = async (): Promise<UserData> => {
	const userDataString = await Storage.get({ key: 'userData' });
	const userDataValue = userDataString.value;

	console.log('user data string ' + userDataValue);

	if (userDataValue) {
		return JSON.parse(userDataValue);
	} else {
		console.error('error on fetch user data ');
		return Promise.reject('error');
	}
};

export const clearUserData = async () => {
	await Storage.clear();
};
