export const VALIDATION_MESSAGE = {
	REQUIRED_FIELD: 'Campo obbligatorio',
	EMAIL_NOT_VALID: "L'email non ha un formato valido",
	PASSWORD_MIN_LENGTH: 'La password deve essere di almeno 6 caratteri',
};

export const REGEX = {
	EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const PLACEHOLDER = {
	INSERT_EMAIL: 'Email',
	INSERT_PASSWORD: 'Password',
	INSERT_NAME: 'Nome',
	INSERT_LASTNAME: 'Cognome',
};

export const ERROR_MESSAGE = {
	WRONG_PASSWORD: 'Password Errata',
	EMAIL_NOT_REGISTERED: 'Email non registrata',
	UNEXPECTED_ERROR: 'Errore imprevisto. Riprovare più tardi.',
};

export const ERROR_CODE = {
	AUTH_WRONG_PASSWORD: 'auth/wrong-password',
	AUTH_USER_NOT_FOUND: 'auth/user-not-found',
};
