import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
	getFirestore,
	collection,
	getDocs,
	doc,
	getDoc,
	query,
	where,
	orderBy,
	limit,
} from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, User } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { clearUserData, getUserData } from './storage';
import { onSnapshot } from 'firebase/firestore';
import getIsoWeek from 'date-fns/getISOWeek';
const moment = require('moment');
const PAGE_SIZE = 10;

const firebaseConfig = {
	apiKey: 'AIzaSyAfKtkRqr9Mu_G2dviNX2_tqcGzVMKDfDM',
	authDomain: 'jackshop-prod.firebaseapp.com',
	databaseURL: 'https://jackshop-prod.firebaseio.com',
	projectId: 'jackshop-prod',
	messagingSenderId: '49330404946',
	appId: '1:49330404946:web:46e8d4a7d650a21f6f698b',
};
// const firebaseConfig = {
// 	apiKey: 'AIzaSyCTLa2IukqFmajMo9ts2hPhxrxCEa2_Fqc',
// 	authDomain: 'jackshop-dev2.firebaseapp.com',
// 	databaseURL: 'https://jackshop-dev2.firebaseio.com',
// 	projectId: 'jackshop-dev2',
// 	storageBucket: 'jackshop-dev2.appspot.com',
// 	messagingSenderId: '1041877293751',
// 	appId: '1:1041877293751:web:e41dc13a76440f76',
// };

const app = firebase.initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west1');
const auth = getAuth();

export const loginWithEmail = async (email: string, password: string) => {
	return signInWithEmailAndPassword(auth, email, password);
};

export const confirmRegistration = async (
	c: string,
	at: string,
	password: string,
	email: string
) => {
	const confirmAccountRegistration = httpsCallable(
		functions,
		'confirmAccountRegistration'
	);

	return confirmAccountRegistration({ c, at, password, email });
};

export const verifyAuthState = (
	authStateCallback: (arg0: User | null) => void
) => {
	auth.onAuthStateChanged((user) => {
		authStateCallback(user);
	});
};

export const getJackpotRealtime = (callback: (doc: any) => void) => {
	const currentWeek = getIsoWeek(new Date());
	const currentYear = moment().year();

	return onSnapshot(
		doc(db, 'jackpot', `${currentYear}_${currentWeek}`),
		callback
	);
};

export const getCustomerCreditRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();

	console.log('get business ' + userData.userUID);

	const q = query(
		collection(db, 'customer-accounts'),
		where('accountUID', '==', userData.userUID)
	);

	return onSnapshot(q, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			console.log(
				'🚀 ~ file: firestore.ts ~ line 96 ~ snapshot.docChanges ~ change',
				change
			);
			callback(change);
		});
	});
};

export const getBusinessAccountInfoRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();

	console.log('get business ' + userData.userUID);
	const q = query(
		collection(db, 'business-accounts'),
		where('accountUID', '==', userData.userUID)
	);

	onSnapshot(q, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			callback(change);
		});
	});
};

/**
 * Get customer transactions
 * @returns
 */
export const getCustomerTransactions = async () => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const q = query(
		collection(db, 'transactions'),
		where('customerUID', '==', userUID),
		orderBy('creationDate', 'desc')
	);

	const querySnapshot = await getDocs(q);
	const results: any[] = [];
	querySnapshot.forEach((doc) => {
		results.push(doc.data());
	});

	return results;
};

/**
 * Get customer transactions
 * @returns
 */
export const getCustomerTransactionsRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const q = query(
		collection(db, 'transactions'),
		where('customerUID', '==', userUID),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getRegisteredBusinessesRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const q = query(
		collection(db, 'business-accounts'),
		where('addedBy', '==', userUID),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getAllRegisteredBusinessesRealtime = async (
	callback: (doc: any) => void
) => {
	const q = query(
		collection(db, 'business-accounts'),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getRegisteredInfluencersRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();
	const userUID = userData.userUID;

	const q = query(
		collection(db, 'influencer-accounts'),
		where('addedBy', '==', userUID),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getAllRegisteredInfluencersRealtime = async (
	callback: (doc: any) => void
) => {
	const q = query(
		collection(db, 'influencer-accounts'),
		orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

export const getAllRegisteredAgentsRealtime = async (
	callback: (doc: any) => void
) => {
	const q = query(
		collection(db, 'agent-accounts')
		// orderBy('creationDate', 'desc')
	);

	onSnapshot(q, callback);
};

/**
 * Get transactions for a specific business
 * @returns
 */
export const getBusinessTransactionsRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();

	const businessUID = userData.userUID;

	console.log('b uid: ' + businessUID);
	const q = query(
		collection(db, 'transactions'),
		where('businessUID', '==', businessUID),
		orderBy('creationDate', 'desc'),
		limit(PAGE_SIZE)
	);

	onSnapshot(q, callback);
};

export const getBusinessCreditRealtime = async (
	callback: (doc: any) => void
) => {
	const userData = await getUserData();

	const businessUID = userData.userUID;
	const q = query(
		collection(db, 'transactions'),
		where('accountUID', '==', businessUID),
		orderBy('creationDate', 'desc'),
		limit(PAGE_SIZE)
	);

	onSnapshot(q, callback);
};

/**
 * Logout from firebase
 */
export const logout = async () => {
	await auth.signOut();
	await clearUserData();
};

/**
 * Get current logged user
 * @returns
 */
export const isUserLogged = () => {
	return auth.currentUser;
};

/**
 * Create a new transaction
 * @param amountSpent
 * @param customerUID
 * @returns
 */
export const createTransaction = (amountSpent: number, customerUID: string) => {
	const createTransaction = httpsCallable(functions, 'createTransaction');
	return createTransaction({
		amountSpent: amountSpent,
		customerUID: customerUID,
	});
};

export const consumeCustomerCredit = (amount: number, customerUID: string) => {
	const consumeCustomerCredit = httpsCallable(
		functions,
		'consumeCustomerCredit'
	);
	return consumeCustomerCredit({
		amount,
		customerUID,
	});
};

/**
 * Create new customer account
 * @param user
 * @returns
 */
// export const createUserWithEmail = (user: any) => {
// 	const createUserWithEmail = httpsCallable(functions, 'createUserWithEmail');
// 	return createUserWithEmail(user);
// };

/**
 * Create new customer account
 * @param user
 * @returns
 */
export const registerCustomer = (user: any) => {
	const registerCustomer = httpsCallable(functions, 'registerCustomer');
	return registerCustomer(user);
};

export const registerAgentAccount = (agent: any) => {
	const registerAgentAccount = httpsCallable(functions, 'registerAgent');
	return registerAgentAccount(agent);
};

export const registerInfluencerAccount = (agent: any) => {
	const registerInfluencerAccount = httpsCallable(
		functions,
		'registerInfluencer'
	);
	return registerInfluencerAccount(agent);
};

export const registerBusinessAccount = (
	businessData: any,
	isOnline: boolean
) => {
	const registerBusinessAccount = httpsCallable(functions, 'registerBusiness');

	return registerBusinessAccount({ ...businessData, isOnline });
};

export const sendConfirmationEmail = (email: string, at: string) => {
	const sendConfirmationEmail = httpsCallable(
		functions,
		'sendConfirmationEmail'
	);

	return sendConfirmationEmail({ email, at });
};

export const updateBusinessCredit = (
	businessUid: string,
	businessCredit: number
) => {
	const updateBusinessCredit = httpsCallable(functions, 'updateBusinessCredit');
	return updateBusinessCredit({ businessUid, businessCredit });
};

export const getRegisteredBusinesses = (
	searchCity: string,
	onlineBusinesses?: boolean
) => {
	const getRegisteredBusinessesFunc = httpsCallable(
		functions,
		'getRegisteredBusinesses'
	);
	return getRegisteredBusinessesFunc({
		searchCity,
		onlineBusinesses,
	});
};
