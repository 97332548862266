import { TextFieldTypes } from '@ionic/core';
import {
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
	useIonRouter,
} from '@ionic/react';
import CustomForm from '../../components/CustomForm/CustomForm';
import './CustomerRegistration.scss';
import * as Yup from 'yup';
import { REGEX, VALIDATION_MESSAGE } from '../../app/utils/constants';
import { registerCustomer } from '../../services/firestore';
import { useEffect } from 'react';
import * as qs from 'qs';

const CustomerRegistration: React.FC = () => {
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const router = useIonRouter();
	let invitedBy: any;

	useEffect(() => {
		invitedBy = qs.parse(router.routeInfo.search, {
			ignoreQueryPrefix: true,
		}).i;
	}, []);

	const fields: any = [
		{
			label: 'Nome',
			props: {
				name: 'name',
				type: 'text' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Nome',
			},
		},
		{
			label: 'Cognome',
			props: {
				name: 'lastname',
				type: 'text' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Cognome',
			},
		},
		{
			label: 'Email',
			props: {
				name: 'email',
				type: 'email' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Email',
			},
		},
	];

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		lastname: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		email: Yup.string()
			.matches(REGEX.EMAIL, VALIDATION_MESSAGE.EMAIL_NOT_VALID)
			.required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	});

	const onSubmit = async (data: any) => {
		presentLoader('Registrazione in corso...');
		try {
			const result: any = await registerCustomer({ ...data, invitedBy });

			JSON.stringify('result ' + JSON.stringify(result));

			if (result.data?.error) {
				if (result.data.error === 'auth/email-already-exists') {
					presentAlert('Email già registrata su JackShop');
				} else {
					presentAlert(`Errore imprevisto durante la creazione dell'account`);
				}
			} else {
				presentAlert(
					'Registrazione avvenuta correttamente! Ti abbiamo inviato una email di conferma per ultimare la registrazione',
					[
						{
							text: 'OK',
							handler: () => {
								router.push('/');
							},
						},
					]
				);
			}
		} catch (e) {
			console.error(e);
			presentAlert("Errore imprevisto durante la creazione dell'account");
		} finally {
			dismissLoader();
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Registrazione</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<CustomForm
					fields={fields}
					validationSchema={validationSchema}
					submitLabel={'REGISTRATI'}
					onSubmit={onSubmit}
				></CustomForm>
			</IonContent>
		</IonPage>
	);
};

export default CustomerRegistration;
