import {
	IonBackButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { bagCheckOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { getRegisteredBusinessesRealtime } from '../../../services/firestore';
import './AgentRegisteredBusinessList.scss';

const AgentRegisteredBusinessList: React.FC = () => {
	const [businesses, setBusinesses] = useState<any[]>([]);

	useEffect(() => {
		getRegisteredBusinessesRealtime((snapshot) => {
			snapshot.docChanges().forEach((change: any) => {
				setBusinesses((prevState) => {
					const pState = [...prevState];

					if (pState.length > 0) {
						const doc = change.doc.data();
						const currentDocTime = doc.creationDate.toDate().getTime();
						const firstDocTime = pState[0].creationDate.toDate().getTime();

						if (currentDocTime > firstDocTime) {
							pState.unshift(change.doc.data());
						} else {
							pState.push(change.doc.data());
						}
					} else {
						pState.push(change.doc.data());
					}

					return pState;
				});
			});
		});
	}, []);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Attività registrate</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className='agent-registered-businesses-list'>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='12'
							size-lg='12'
							size-xl='12'
						>
							<IonList className='business-list-wrapper'>
								{businesses?.length === 0 && (
									<div className='no-data ion-padding ion-margin ion-text-center'>
										Non sono presenti attività registrate
									</div>
								)}
								{businesses?.map((b) => {
									return (
										<div className='item-wrapper' key={b.creationDate.seconds}>
											<p className='header-text'>{b.businessName}</p>
											<IonItem
												key={b.creationDate.seconds}
												lines='full'
												className='list-item'
											>
												{/* <IonIcon
										slot='start'
										icon={bagCheckOutline}
										className='icon'
									></IonIcon> */}
												<IonLabel className='name'>
													<p className='text'>
														<span className='amount'>Titolare </span>
														{b.businessOwner}
													</p>
													<p className='text'>
														<span className='amount'>Città </span>
														{b.city}
													</p>
													<p className='text'>
														<span className='amount'>Indirizzo </span>
														{b.address}
													</p>
													<p className='text'>
														<span className='amount'>Tel. </span>
														{b.telephone}
													</p>
													<p className='text'>
														<span className='amount'>P. IVA </span>
														{b.vatNumber}
													</p>
													<small className='text'>
														Il{' '}
														{new Intl.DateTimeFormat('it', {
															dateStyle: 'long',
															timeStyle: 'short',
														}).format(b.creationDate.toDate())}
													</small>
												</IonLabel>
											</IonItem>
										</div>
									);
								})}
							</IonList>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default AgentRegisteredBusinessList;
