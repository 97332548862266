export enum UserType {
	CUSTOMER = 'CUSTOMER',
	BUSINESS = 'BUSINESS',
	INFLUENCER = 'INFLUENCER',
	AGENT = 'AGENT',
	SUPERADMIN = 'SUPERADMIN',
}

export interface UserAuthState {
	userUID?: string;
	name?: string;
	userType?: UserType;
}
