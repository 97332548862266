import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonLoading,
	useIonRouter,
	useIonToast,
} from '@ionic/react';
import { Dispatch } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import darkBorder from '../../../assets/dark-border.png';
import logo from '../../../assets/logo-transp.png';
import redBorder from '../../../assets/red-border.png';
import './AgentHome.scss';

const AgentLogin: React.FC = () => {
	const dispatch: Dispatch<any> = useAppDispatch();
	const router = useIonRouter();
	const [presentLoader, dismissLoader] = useIonLoading();
	const [presentToast, dismissToast] = useIonToast();

	const userType = useAppSelector((state) => state.authSlice.userType);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Home</IonTitle>
					<IonButtons slot='start'>
						<IonMenuButton autoHide={false} menu='agentMenu'></IonMenuButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className='heading-logo-image-wrapper'>
					<img className='logo' src={logo} />
					<img className='dark-border' src={darkBorder} />
					<img className='red-border' src={redBorder} />
				</div>
				<IonGrid>
					<IonRow className='ion-justify-content-center'>
						<IonCol
							size-xs='12'
							size-sm='12'
							size-md='4'
							size-lg='4'
							size-xl='4'
						>
							<div className='ion-padding buttons-wrapper'>
								<IonButton
									className='standard-btn'
									routerDirection='forward'
									color='primary'
									fill='solid'
									routerLink='/agent-register-business'
								>
									Registra nuova attività
								</IonButton>
								<IonButton
									className='standard-btn'
									routerDirection='forward'
									color='primary'
									fill='solid'
									routerLink='/agent-register-influencer'
								>
									Registra influencer
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default AgentLogin;
