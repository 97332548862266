import { TextFieldTypes } from '@ionic/core';
import {
	IonBackButton,
	IonButtons,
	IonCheckbox,
	IonContent,
	IonHeader,
	IonItem,
	IonLabel,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonLoading,
	useIonRouter,
	useIonToast,
} from '@ionic/react';
import { Dispatch, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import darkBorder from '../../../assets/dark-border.png';
import logo from '../../../assets/logo-transp.png';
import redBorder from '../../../assets/red-border.png';
import CustomForm from '../../../components/CustomForm/CustomForm';
import { registerBusinessAccount } from '../../../services/firestore';
import './AgentRegisterNewBusiness.scss';
import * as Yup from 'yup';
import { REGEX, VALIDATION_MESSAGE } from '../../../app/utils/constants';

const AgentRegisterNewBusiness: React.FC = () => {
	const dispatch: Dispatch<any> = useAppDispatch();
	const router = useIonRouter();
	const [presentAlert] = useIonAlert();
	const [presentLoader, dismissLoader] = useIonLoading();
	const [presentToast, dismissToast] = useIonToast();
	const [clearForm, setClearForm] = useState(false);
	const [onlineBusiness, setOnlineBusiness] = useState(false);
	const userType = useAppSelector((state) => state.authSlice.userType);

	const fields: any = [
		{
			label: 'Nome attività',
			props: {
				name: 'businessName',
				type: 'text' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Nome attività',
			},
		},
		{
			label: 'Nome titolare',
			props: {
				name: 'businessOwner',
				type: 'text' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Titolare attività',
			},
		},
		{
			label: 'Indirizzo',
			props: {
				name: 'address',
				type: 'text' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Indirizzo',
			},
		},
		{
			label: 'Città',
			props: {
				name: 'city',
				type: 'text' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Città',
			},
		},
		{
			label: 'Partita IVA',
			props: {
				name: 'vatNumber',
				type: 'text' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Partita IVA',
			},
		},
		{
			label: 'Numero di telefono',
			props: {
				name: 'telephone',
				type: 'tel' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Numero di telefono',
			},
		},
		{
			label: 'Email',
			props: {
				name: 'email',
				type: 'email' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Email',
			},
		},
	];

	const onlineFields: any = [
		{
			label: 'Nome attività',
			props: {
				name: 'businessName',
				type: 'text' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'Nome attività',
			},
		},
		{
			label: 'URL sito web',
			props: {
				name: 'url',
				type: 'url' as TextFieldTypes,
				// inputmode: 'text',
				placeholder: 'URL sito web',
			},
		},
	];

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.matches(REGEX.EMAIL, VALIDATION_MESSAGE.EMAIL_NOT_VALID)
			.required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		businessName: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		businessOwner: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		address: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		city: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		vatNumber: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		telephone: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	});

	const onlineBusinessValidationSchema = Yup.object().shape({
		businessName: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
		url: Yup.string().required(VALIDATION_MESSAGE.REQUIRED_FIELD),
	});

	const onSubmit = async (data: any) => {
		console.log(
			'🚀 ~ file: AgentRegisterNewBusiness.tsx ~ line 146 ~ onSubmit ~ data',
			data
		);
		presentLoader('Registrazione in corso...');
		setClearForm(false);
		try {
			const result: any = await registerBusinessAccount(data, onlineBusiness);

			if (result.data?.error) {
				if (
					result.data.error === 'auth/email-already-exists' ||
					result.data.code === 'BUSINESS_ACCOUNT_ALREADY_EXISTS'
				) {
					presentAlert('Email già registrata su JackShop');
				} else {
					presentAlert(`Errore imprevisto durante la creazione dell'account`);
				}
			} else {
				presentAlert('Registrazione avvenuta correttamente', [
					{
						text: 'OK',
						handler: () => {
							setClearForm(true);
							router.push('/agent-home');
						},
					},
				]);
			}
		} catch (e) {
			console.error(e);
			presentAlert("Errore imprevisto durante la creazione dell'account");
		} finally {
			dismissLoader();
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color='tertiary'>
					<IonTitle>Registra attività</IonTitle>
					<IonButtons slot='start'>
						<IonBackButton></IonBackButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className='ion-padding'>
					<IonItem className='is-online-wrapper'>
						<IonLabel>Attività Online</IonLabel>
						<IonCheckbox
							checked={onlineBusiness}
							onIonChange={(e) => setOnlineBusiness(e.detail.checked)}
						/>
					</IonItem>
					{onlineBusiness && (
						<CustomForm
							fields={onlineFields}
							validationSchema={onlineBusinessValidationSchema}
							submitLabel={'Registra attività'}
							onSubmit={onSubmit}
							clearForm={clearForm}
						></CustomForm>
					)}
					{!onlineBusiness && (
						<CustomForm
							fields={fields}
							validationSchema={validationSchema}
							submitLabel={'Registra attività'}
							onSubmit={onSubmit}
							clearForm={clearForm}
						></CustomForm>
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default AgentRegisterNewBusiness;
